import { FC } from 'react'
import { useQueryClient } from 'react-query'
import DOMPurify from 'dompurify'

import { RichTextEditor } from '../RichTextEditor/RichtextEditor'
import axiosRequest from '~/utils/axios'
import { listCaseEmailKey } from '~/api/CaseService'
import { useMutation } from '~/components/Providers/ApiProvider'
import { ALLOWED_ATTR, ALLOWED_TAGS } from '../RichTextEditor/utils'
import { listCommunicationsKey } from '~/api/CommunicationService'
import { listPersonTodoKey } from '~/api/PatientToDoService'
import { FileWithType } from './EmailFileUpload'

interface EmailEditorProps {
  content: string
  case_id: number
  onCancel: () => void
}
export const useSendCaseEmail = (caseId: number) => {
  const client = useQueryClient()

  return useMutation(
    (formData: FormData) =>
      axiosRequest(
        'POST',
        'multipart/form-data',
        true,
        `/providers/me/cases/${caseId}/email/`,
        formData
      ),
    {
      onSuccess: async () => {
        await client.invalidateQueries([listCaseEmailKey])
        await client.invalidateQueries([listCommunicationsKey])
        await client.invalidateQueries([listPersonTodoKey])
      },
    },
    { success: 'Email sent!', error: 'Email could not be sent' }
  )
}

export const EmailEditor: FC<EmailEditorProps> = props => {
  const { mutateAsync: mutateSendCaseEmail, isLoading: isLoadingSendCaseEmail } = useSendCaseEmail(
    props.case_id
  )
  const onSubmit = async (content: string, files?: FileWithType[]) => {
    const santisedContent = DOMPurify.sanitize(content, {
      ALLOWED_TAGS: ALLOWED_TAGS,
      ALLOWED_ATTR: ALLOWED_ATTR,
    })
    const formData = new FormData()
    formData.append('message', santisedContent)
    files?.forEach(file => {
      if (file?.data) formData.append('attachments', file.data)
    })
    await mutateSendCaseEmail(formData)
  }

  return (
    <RichTextEditor
      content={props.content}
      onSubmit={onSubmit}
      isLoading={isLoadingSendCaseEmail}
      onCancel={props.onCancel}
    />
  )
}
