import { FC, useState } from 'react'
import Moment from 'moment'
import SmsFailedIcon from '@mui/icons-material/SmsFailed'

import { Box, Tooltip, Typography } from '@mui/material'
import { RichTextEditorDisplay } from '../RichTextEditor/RichTextEditorDisplay'

interface EmailMessageDisplayProps {
  content: string
  to_address: string[]
  from_address: string
  send_at: string
  maxLength?: number
  key: number
  status: string
  attachments: {
    fileName: string
    attachmentId: string
    presignedUrl: string
    isPotentialMalware: boolean
  }[]
}

export const EmailMessageDisplay: FC<EmailMessageDisplayProps> = props => {
  const date = Moment(new Date(props.send_at))
  const messageDate = date.format('MMM. D, YYYY h:mm a')
  const [maxLength, setMaxLength] = useState(props.maxLength)
  const isMalwareFound = props.attachments.some(relation => relation.isPotentialMalware === true)
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      onClick={() => (maxLength ? setMaxLength(undefined) : setMaxLength(props.maxLength))}
      key={props.key}
      mt={2}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexGrow={1}
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" justifyContent="space-around">
          <Typography fontSize={13} fontWeight="bold">
            {props.from_address}
          </Typography>
          <Typography fontSize={13}>to {props.to_address[0]}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-around" justifyItems="end">
          <Typography fontSize={12}>{messageDate}</Typography>
        </Box>
        {props.status === 'FAILED' && (
          <Tooltip title={props.status}>
            <SmsFailedIcon color="error" />
          </Tooltip>
        )}
      </Box>
      <RichTextEditorDisplay content={props.content} maxLength={maxLength} />
      {isMalwareFound && props.attachments.length ? (
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            borderWidth: '1px',
            borderColor: theme.palette.error.dark,
            borderStyle: 'solid',
            borderRadius: '10px',
            marginBottom: '10px',
            paddingY: '15px',
            paddingX: '20px',
            marginX: '5px',
          })}
        >
          <Typography sx={theme => ({ color: theme.palette.error.dark, fontSize: '12px' })}>
            Potential Malware
          </Typography>
          {props.attachments.map((attachment, index) => (
            <Box key={index} display="flex" flexDirection="row" alignItems="center" mx={3}>
              <Box
                key={index}
                sx={theme => ({
                  display: 'flex',
                  flexDirection: 'row',
                  width: '5px',
                  height: '5px',
                  borderColor: theme.palette.error.dark,
                  backgroundColor: theme.palette.error.dark,
                  borderRadius: '5px',
                  justifyContent: 'space-between',
                  marginX: '5px',
                })}
              />
              <Typography
                key={index}
                sx={theme => ({ color: theme.palette.error.dark, fontSize: '12px' })}
              >
                Blocked File: {attachment.fileName}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box display="flex" flex={1} flexDirection="row">
          {props.attachments.map((attachment, index) => (
            <Box
              key={index}
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                borderWidth: '1px',
                borderColor: theme.palette.grey[200],
                borderStyle: 'solid',
                borderRadius: '20px',
                marginBottom: '10px',
                paddingY: '5px',
                paddingX: '10px',
                marginX: '5px',
                textDecoration: 'none',
              })}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={attachment.presignedUrl}
                key={index}
                style={{ color: '#0077DC', textDecoration: 'none' }}
              >
                {attachment.fileName}
              </a>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
