import {
  AutoAwesome as AutoAwesomeIcon,
  ChatBubble,
  Message as MessageIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Dialog,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import CaseEditor, { Props as CaseEditorProps } from '../Cases/CaseEditor'
import {
  ICase,
  ICaseCategory,
  ICaseRelation,
  MessageIdentifier,
  workunitCompleteStates,
} from '~/models/Case'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { Suspense, lazy, useContext, useEffect, useState } from 'react'
import { listCarePlansKey, useCarePlans } from '~/api/CarePlanService'
import {
  useAddNewCaseRelation,
  useCase,
  useCaseCategoriesById,
  useCaseEvents,
  useCaseMessageTranscript,
  useUpdateCase,
} from '~/api/CaseService'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import CarePlansModal from '../Careplans/CarePlansModal'
import type { FC } from 'react'
import { ICarePlan } from '~/models/CarePlan'
import ListIcon from '@mui/icons-material/List'
import Loader from '~/components/Loader'
import CaseTag from './CaseTag'

import Moment from 'moment'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import NextActionDate from '~/components/WorkUnit/NextActionDate'
import { PatientTodo } from '~/models/PatientTodoItem'
import { PatientUser } from '~/types'
import { Worklist } from '~/redux/slices/worklists'
import classNames from 'classnames'
import { getDueDateLabel, getUpdatedAtLabel } from '~/components/Tasks/utils'
import { getMessageCaseRelation } from '../Cases/utils'
import { getTasksSuccess } from '~/redux/actions/tasks'
import { listPersonTodoKey } from '~/api/PatientToDoService'
import { makeStyles } from 'tss-react/mui'
import qs from 'query-string'
import { styled } from '@mui/material/styles'
import { useDrop } from 'react-dnd'
import { useFocusCase } from '../Education/utils'
import { useQueryClient } from 'react-query'
import { useUpdateTaskParent } from '~/api/TaskService'
import UserGroupSelect from '../Generic/UserGroupSelect'
import InlineEditableField from './InlineEditableField'
import { useGroupOptions } from '~/utils/useGroupOptions'
import chatSlice from '~/redux/slices/chat/slice'
import { queueNotification } from '~/redux/actions/notifications'
import CaseMemberFacingInfo from './CaseMemberFacingInfo'
import InlineLoader from '../InlineLoader'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'
import { RichTextEditorDisplay } from '../RichTextEditor/RichTextEditorDisplay'
import EmailIcon from '@mui/icons-material/Email'
import { useFeatureSwitch } from '~/utils/useFeatureSwitch'
import { HEALTH_PLAN_OPERATIONS } from './constants'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import { EmailListView } from '../Email/EmailListView'
import { CASE_EMAIL_SWITCH, SEND_EMAIL_FEATURE_FLAG } from '../Email/constants'

const iconSize = '2.2rem'

const useStyles = makeStyles()(theme => {
  return {
    highlightContent: {
      backgroundColor: theme.palette.background.highlight,
    },
    contentTitle: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      fontSize: '1.6rem',
    },
    contentSubTitle: {
      marginLeft: iconSize,
      wordBreak: 'break-word',
      fontSize: '1.4rem',
      color: theme.palette.text.secondary,
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
    },
    showCareplanLink: {
      color: theme.palette.primary.main,
      background: 'none',
      border: 'none',
      marginLeft: '2rem',
      marginBottom: '0.5rem',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
    iconButton: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    machineGeneratedIcon: {
      color: 'black',
      position: 'absolute',
      paddingLeft: '0.5rem',
      paddingTop: '0.3rem',
      paddingRight: '2rem',
      fontSize: '1.6rem',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
      },
    },
    caseDescriptionHolder: {
      '&:before': {
        content: '""',
        position: 'absolute',
        backgroundColor: '#E0E0E0',
        width: 'auto',
        height: '1px',
        left: 23,
        right: 22,
      },
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
    },
    msgTaskHolderWorklist: {
      alignSelf: 'flex-end',
      width: '100%',
    },
    parentCategory: {
      marginLeft: iconSize,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      fontSize: '1.2rem',
      color: theme.palette.text.secondary,
      display: 'inline-flex',
      marginRight: 11,
    },
    msgTaskHolder: {
      display: 'inline-flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    updatedHolder: {
      width: '30%',
      alignSelf: 'flex-end',
    },
    updatedContent: {
      color: theme.palette.text.secondary,
      justifyContent: 'right',
      display: 'flex',
      paddingRight: 20,
    },
    contentHolder: {
      width: '70%',
    },
    mainIcon: {
      color: theme.palette.primary.main,
      justifyContent: 'right',
      display: 'flex',
      paddingRight: 10,
    },
    mainIconGrey: {
      color: theme.palette.secondary.main,
      justifyContent: 'right',
      display: 'flex',
      paddingRight: 10,
    },
    textContent: {
      fontSize: '1.2rem',
      paddingLeft: 4,
      fontWeight: 500,
    },
    assigneeName: {
      fontSize: '1.4rem',
      paddingLeft: 6,
    },
    ownerDropdown: {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        fontSize: '1.4rem',
      },
    },
    autoCompleteText: {
      fontSize: '1.4rem',
    },
  }
})

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    TransitionProps={{ unmountOnExit: true }}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  padding: 0,
}))

const AccordionHeader: React.FC<{
  highlight?: boolean
  selectedCaseDetail?: number
  onClick?: Function
}> = props => {
  const { classes } = useStyles()

  const headerClass = classNames({
    [classes.highlightContent]: props.highlight,
  })

  return (
    <AccordionSummary
      aria-label="Expand"
      aria-controls={`${props.selectedCaseDetail}-content`}
      id={`${props.selectedCaseDetail}-header`}
      style={{ flexGrow: 1 }}
      className={headerClass}
    >
      <Box
        style={{ flexGrow: 1 }}
        onClick={e => {
          var target = (e.target as HTMLElement).closest('.classToStopPropagation')
          var popperTarget = (e.target as HTMLElement).closest('.MuiAutocomplete-popper')
          if (target !== null || popperTarget !== null) {
            e.stopPropagation()
            e.preventDefault()
          } else {
            if (props.onClick) props.onClick(e)
          }
        }}
      >
        {props.children}
      </Box>
    </AccordionSummary>
  )
}

const HeaderNextActionDate = ({
  todo,
  editable = false,
  onChange,
}: {
  todo: PatientTodo
  editable?: boolean
  onChange?: (dueDate: string) => Promise<void>
}) => {
  const [dueDate, setDueDate] = useState(todo.dueDate)
  useEffect(() => {
    setDueDate(todo.dueDate)
  }, [todo.dueDate])
  return (
    <NextActionDate
      date={dueDate}
      editable={editable}
      newTodos={true}
      onChange={async value => {
        setDueDate(value)
        await onChange?.(value)
      }}
    />
  )
}

const ToDoHeader: FC<{
  parentId: number
  todoContent: PatientTodo
  patient: PatientUser
  selectedWorklist: Worklist | undefined
  expandCase: boolean
  isClosed?: boolean
  disableEdit?: boolean
  parentCategory: string
  carePlanTitle?: string
  isTodoTaskUpdating: boolean
  isTodoNoteUpdating: boolean
}> = props => {
  const {
    parentId,
    todoContent,
    patient,
    children,
    expandCase,
    isClosed,
    disableEdit,
    parentCategory,
    carePlanTitle,
    isTodoTaskUpdating,
    isTodoNoteUpdating,
  } = props

  switch (todoContent.contentType) {
    case 'case': {
      return (
        <CaseHeader
          patient={patient}
          parentId={parentId}
          todoContent={todoContent}
          expandCase={expandCase}
          isClosed={isClosed ?? false}
          disableEdit={disableEdit}
          parentCategory={parentCategory}
          carePlanTitle={carePlanTitle}
          isTodoTaskUpdating={isTodoTaskUpdating}
          isTodoNoteUpdating={isTodoNoteUpdating}
        >
          {children}
        </CaseHeader>
      )
    }
    case 'careplan': {
      return (
        <CarePlanHeader
          patient={patient}
          parentId={parentId}
          todoContent={todoContent}
          parentCategory={parentCategory}
        >
          {children}
        </CarePlanHeader>
      )
    }
    case 'chatmessagev2': {
      return <ChatMessageHeader todoContent={todoContent}>{children}</ChatMessageHeader>
    }
  }

  return <Box>Unknown Todo Item</Box>
}

export default ToDoHeader

const CaseHeader: FC<{
  parentId: number
  todoContent: Extract<PatientTodo, { contentType: 'case' }>
  patient: PatientUser
  expandCase?: boolean
  isClosed?: boolean
  disableEdit?: boolean
  parentCategory: string
  carePlanTitle?: string
  isTodoTaskUpdating: boolean
  isTodoNoteUpdating: boolean
}> = props => {
  const {
    parentId,
    todoContent,
    patient,
    children,
    expandCase,
    isClosed,
    disableEdit,
    parentCategory,
    carePlanTitle,
    isTodoTaskUpdating,
    isTodoNoteUpdating,
  } = props
  const { classes } = useStyles()
  const history = useHistory()
  const location = useLocation()
  const navigateToCase = (id: ICase['id']) => {
    history.push({
      pathname: 'todos', // Assumes we are already on /patients/:id/...
      search: qs.stringify({
        caseId: id,
      }),
    })
  }

  const navigateToCarePlan = (id: ICarePlan['id']) => {
    history.push({
      pathname: 'todos',
      search: qs.stringify({
        careplanId: id,
      }),
    })
  }
  const { mutateAsync: handleUpdateCase, isLoading: isLoadingUpdateCase } = useUpdateCase()
  const { mutateAsync: mutateAddNewCaseRelation, isLoading: isLoadingAddNewCaseRelation } =
    useAddNewCaseRelation(patient.person.id)

  const isUpdatingCase = isLoadingUpdateCase || isLoadingAddNewCaseRelation

  const { mutateAsync: handleUpdateCaseStatus, isLoading: isUpdatingCaseStatus } = useUpdateCase()

  const { mutateAsync: handleUpdateTaskParent, isLoading: isUpdatingTaskParent } =
    useUpdateTaskParent()

  const [isFetchingMessageCaseRelation, setIsFetchingMessageCaseRelation] = useState(false)
  const [showEmailModel, setShowEmailModel] = useState<boolean>(false)

  const isCaseMutationInProgress = isUpdatingTaskParent || isFetchingMessageCaseRelation

  const dispatch = useDispatch()
  const setMessageId = (patientId, uid, id) =>
    dispatch(chatSlice.actions.setMessageId({ patientId, uid, id }))

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['messageIdentifier', 'patientTodoItem', 'multiselectedMessages'],
      /* item is set to any below so that it can accept multiple types during drag and drop. Since we lose some
      // inherent typing with drag and drop, we can either use any or unknown here. We are relatively safe to use
      // any since we do some additional type checking against the drag and drop "getItemType" and assert the type
      based on the specific drag and drop "getItemType". */
      drop: async (item: any, monitor) => {
        const selectedCase = todoContent.contentObject
        if (!selectedCase) {
          return
        }
        switch (monitor.getItemType()) {
          case 'messageIdentifier': {
            // get message case relation for dragged message
            setIsFetchingMessageCaseRelation(true)
            const relation: ICaseRelation = await getMessageCaseRelation(
              item as MessageIdentifier,
              patient.id,
              setMessageId
            )
            setIsFetchingMessageCaseRelation(false)
            await mutateAddNewCaseRelation({
              caseObj: selectedCase,
              relationData: relation,
            })
            break
          }
          case 'multiselectedMessages': {
            setIsFetchingMessageCaseRelation(true)
            const newRelations: ICaseRelation[] = await Promise.all(
              (item as MessageIdentifier[]).map(id =>
                getMessageCaseRelation(id, patient.id, setMessageId)
              )
            )
            setIsFetchingMessageCaseRelation(false)
            await Promise.all(
              newRelations.map(newRelation =>
                mutateAddNewCaseRelation({
                  caseObj: selectedCase,
                  relationData: newRelation,
                })
              )
            )
            break
          }
          case 'patientTodoItem': {
            if (item !== undefined) {
              item = item as PatientTodo
              await handleUpdateTaskParent({
                taskId: item.contentObject.id,
                oldObjectId: item.objectId,
                newObjectId: todoContent.objectId,
                oldContentType: item.contentType,
              })
            }
            break
          }
        }
      },
      // While a case is in the process of being updated, dragging something else into the case could result in an
      // update using stale data, potentially overwriting the previous update (and we don't have optimistic
      // concurrency control to prevent this from happening). To guard against stale updates, prevent dropping while a
      // mutation is in progress (and show a loader or some other indicator).
      canDrop: () => !isCaseMutationInProgress,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [todoContent, isCaseMutationInProgress]
  )

  // Scroll to selected case.
  const [scrollEl, setScrollEl] = useState<HTMLDivElement | null>(null)
  const { setSelectedCaseId, selectedCaseId, selectPayload } = useContext(SelectedTodoContext)
  useFocusCase(selectedCaseId?.toString())

  const [focusDesp, setFocusDesp] = useState<HTMLDivElement | null>(null)
  const [editingCase, setEditingCase] = useState<boolean>(false)
  const [editingCaseForModal, setEditingCaseForModal] = useState<boolean>(false)
  const [viewingCaseHistory, setViewingCaseHistory] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [expandHeader, setExpandHeader] = useState<boolean>(false)
  const [forceExpanded, setForceExpanded] = useState<boolean>(false)

  const { result: caseCategoriesById } = useCaseCategoriesById()
  // Incase of person profile we will not have the patient id
  const { data: patientCarePlans } = useCarePlans({
    patientId: patient.id ? patient.id : undefined,
  })

  useEffect(() => {
    if (!selectedCaseId || selectedCaseId !== parentId) {
      return
    }

    setForceExpanded(true)

    // expand the case if navigated from navigation tab
    if (expandCase) {
      setExpandHeader(expandCase)
    }

    if (selectPayload?.type == 'add' && focusDesp?.textContent == '') {
      focusDesp?.focus()
    }

    scrollEl?.scrollIntoView()
  }, [scrollEl, selectedCaseId, selectPayload, parentId, expandCase, focusDesp])

  const findCarePlan = (carePlanId: number) => {
    return patientCarePlans?.find(carePlan => carePlan.id === carePlanId)
  }

  let carePlan: ICarePlan | null = null

  const selectedCase = todoContent.contentObject

  const { selectedCaseOwnerGroupOption, flatOptions } = useGroupOptions(
    selectedCase?.ownerGroup || null,
    false,
    null,
    patient.person.careTeam,
    isClosed
  )
  const isCaseEmailEnabled = useFeatureSwitch(CASE_EMAIL_SWITCH)?.active
  const isCaseSendEmailEnabled = useFeatureFlag(SEND_EMAIL_FEATURE_FLAG)

  if (!selectedCase) {
    return <></>
  }

  const caseCategory: ICaseCategory | undefined = caseCategoriesById[selectedCase.category]
  const caseTitle = caseCategory?.title ?? ''
  const isCaseMachineGenerated = selectedCase.isProposed
  const linkedContentCount = selectedCase.relations.filter(
    content => content.contentType == 'chatmessagev2' || content.contentType == 'phonecall'
  ).length
  const linkedCarePlanId = selectedCase.relations.find(
    content => content.contentType == 'careplan'
  )?.objectId
  if (linkedCarePlanId) {
    carePlan = findCarePlan(linkedCarePlanId) ?? null
  }
  const linkedEmailContent = selectedCase.relations.filter(
    content => content.contentType == 'email'
  )
  const emailAttachments = linkedEmailContent.flatMap(
    item => item?.contentObject?.attachments || []
  )
  const latestEmail = linkedEmailContent[linkedEmailContent.length - 1]?.contentObject
  const isLatestEmailFailed =
    latestEmail?.sender === 'Mail Delivery Subsystem <mailer-daemon@googlemail.com>' ||
    latestEmail?.status === 'FAILED'
  const latestEmailContent = latestEmail?.snippet
    ? isLatestEmailFailed
      ? `<span style="color: #E12F2F">${latestEmail?.snippet}</span>`
      : latestEmail?.snippet
    : isLatestEmailFailed
    ? `<span style="color: #E12F2F">${latestEmail?.message}</span>`
    : latestEmail?.message

  const showExternalEmail =
    isCaseEmailEnabled &&
    ((caseCategory?.parent === HEALTH_PLAN_OPERATIONS && isCaseSendEmailEnabled) ||
      linkedEmailContent.length > 0)

  const hasIncompleteTasks =
    todoContent.items && todoContent.items.some(item => !item.contentObject.isComplete)

  // If the case has incomplete tasks, do not show done/deferred status in drop down
  const availableActions =
    hasIncompleteTasks && selectedCase?.statesWithCategories
      ? selectedCase.actions?.filter(
          action =>
            !workunitCompleteStates.includes(
              selectedCase?.statesWithCategories?.find(
                stateWithCategory => stateWithCategory['state']['name'] == action.dest
              )?.category
            )
        )
      : selectedCase.actions

  const onCaseActionChange = async action => {
    await handleUpdateCaseStatus({
      id: selectedCase.id,
      action: action,
    })
  }

  const handleOnAfterChangeCase = (caseObj: ICase) => {
    if (caseObj) {
      setSelectedCaseId(caseObj.id)
    } else {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Case Creation Failed',
        })
      )
    }
  }
  const copyValueToClipboard = async (value: string) => {
    await navigator.clipboard.writeText(value)
    dispatch(queueNotification({ variant: 'success', message: 'Case id copied to clipboard' }))
  }

  const isSelected = !!selectedCaseId && selectedCaseId === parentId

  return (
    <Accordion
      style={{
        borderTop: '10',
        borderBottom: '10',
        marginBottom: '10',
      }}
      expanded={forceExpanded}
    >
      <AccordionHeader
        highlight={isSelected}
        selectedCaseDetail={selectedCase.id}
        onClick={() => setForceExpanded(current => !current)}
      >
        <div
          ref={drop}
          style={{
            backgroundColor: isOver ? 'yellow' : 'inherit',
          }}
        >
          {isCaseMutationInProgress ? <Loader /> : null}
          <div ref={setScrollEl}>
            <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex" flex={1}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Box paddingLeft={1.8} display="flex" alignItems="center">
                    <Box paddingLeft={1} display="grid" flexGrow={1}>
                      <Typography className={classes.contentTitle}>
                        {caseTitle}{' '}
                        {isCaseMachineGenerated ? (
                          <IconButton
                            className={classes.machineGeneratedIcon}
                            style={{
                              opacity: '1',
                            }}
                          >
                            <AutoAwesomeIcon fontSize="inherit" />
                          </IconButton>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                  {carePlan && workunitCompleteStates.includes(selectedCase.statusCategory) && (
                    <button
                      key={carePlan.id}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (carePlan) {
                          navigateToCarePlan(carePlan.id)
                        }
                      }}
                      className={classes.showCareplanLink}
                      style={{ display: 'block', padding: '0', opacity: '1' }}
                    >
                      <Box paddingLeft={4}>Re: {carePlan?.title}</Box>
                    </button>
                  )}
                  <InlineEditableField
                    defaultValue={selectedCase?.description ? selectedCase.description : undefined}
                    selectedCase={selectedCase.id}
                    valueToBeUpdated={'description'}
                    placeholder={'+ Add description'}
                    isClosed={isClosed ? isClosed : false}
                    focus={setFocusDesp}
                    showError={selectPayload?.type == 'add'}
                    setEditingCase={setEditingCase}
                  />
                  {isCaseEmailEnabled && linkedEmailContent.length > 0 && (
                    <Box paddingLeft={3} flexGrow={1}>
                      <Typography
                        sx={{ fontSize: '1.2rem', fontWeight: 'bold', paddingY: '1.5rem' }}
                      >
                        Linked Email Threads
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="column"
                        bgcolor={'#F4F4F4'}
                        borderRadius={3}
                      >
                        <Box
                          flexGrow={1}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Typography
                            sx={{
                              fontSize: '1.2rem',
                              fontWeight: 'bold',
                              padding: '1.5rem',
                              color: isLatestEmailFailed ? '#E12F2F' : null,
                            }}
                          >
                            {latestEmail.sender}
                          </Typography>
                          <RichTextEditorDisplay content={latestEmailContent} maxLength={80} />
                          <Box
                            sx={{
                              padding: '0.5rem',
                              alignContent: 'flex-end',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setShowEmailModel(!showEmailModel)
                                setAnchorEl(null)
                              }}
                            >
                              <EmailIcon color="info" fontSize="small"></EmailIcon>
                              <Typography
                                sx={{
                                  fontSize: '1.2rem',
                                  fontWeight: 'bold',
                                  color: '#0077DC',
                                  paddingLeft: '0.5rem',
                                }}
                              >
                                {linkedEmailContent.length}
                              </Typography>
                            </IconButton>
                          </Box>
                        </Box>
                        {emailAttachments.length > 0 && (
                          <>
                            <Divider />
                            <Box p={2}>
                              <Typography
                                sx={{
                                  fontSize: '1.2rem',
                                }}
                              >
                                {emailAttachments.length} Attached Files:
                              </Typography>
                              {/* When there is more than 3 attachments in the email thread (all the email in the case), 
                              list just 1st 3 file name and rest of file as +N files*/}
                              {emailAttachments.map(
                                (attachment, index) =>
                                  index < 3 && (
                                    <Box
                                      key={index}
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                      mx={3}
                                    >
                                      <Box
                                        key={index}
                                        sx={theme => ({
                                          display: 'flex',
                                          flexDirection: 'row',
                                          width: '5px',
                                          height: '5px',
                                          borderColor: theme.palette.grey['500'],
                                          backgroundColor: theme.palette.grey['500'],
                                          borderRadius: '5px',
                                          justifyContent: 'space-between',
                                          marginX: '5px',
                                        })}
                                      />
                                      <Typography key={index} sx={theme => ({ fontSize: '12px' })}>
                                        {attachment.fileName}
                                      </Typography>
                                      {index == 2 && emailAttachments.length > 2 ? (
                                        <Typography
                                          key={index}
                                          sx={theme => ({ fontSize: '12px' })}
                                        >
                                          ... + {emailAttachments.length - 3} files
                                        </Typography>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  )
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </div>
              </Box>
              <Box marginLeft={1} display="flex" alignItems="center" justifyContent="flex-end">
                {editingCase ||
                isLoadingUpdateCase ||
                isLoadingAddNewCaseRelation ||
                isUpdatingCaseStatus ||
                isUpdatingTaskParent ||
                isFetchingMessageCaseRelation ||
                isTodoTaskUpdating ||
                isTodoNoteUpdating ? (
                  <Box
                    sx={theme => ({
                      position: 'relative',
                      justifyContent: 'right',
                      display: 'flex',
                      color: theme.palette.primary.light,
                    })}
                  >
                    <InlineLoader />
                  </Box>
                ) : null}
                <Box
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <IconButton
                    className={classes.iconButton}
                    onClick={e => {
                      setAnchorEl(e.currentTarget)
                    }}
                    size="large"
                    disabled={todoContent.contentType == 'case' && disableEdit}
                    sx={theme => ({
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      borderRadius: 'unset',
                    })}
                  >
                    <MoreVertIcon className={classes.icon} />
                  </IconButton>
                  <Menu
                    MenuListProps={{
                      disablePadding: true,
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={e => {
                      setAnchorEl(null)
                    }}
                  >
                    {!isCaseMachineGenerated && !isClosed && (
                      <MenuItem
                        onClick={() => {
                          setEditingCaseForModal(!editingCaseForModal)
                          setAnchorEl(null)
                        }}
                      >
                        Change case
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        setViewingCaseHistory(!viewingCaseHistory)
                        setAnchorEl(null)
                      }}
                    >
                      History
                    </MenuItem>
                    {showExternalEmail && (
                      <MenuItem
                        onClick={() => {
                          setShowEmailModel(!showEmailModel)
                          setAnchorEl(null)
                        }}
                      >
                        Show External Email
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => copyValueToClipboard(selectedCase.id.toString())}>
                      Copy Case ID: {selectedCase.id}
                    </MenuItem>
                    <CaseMessageTranscriptMenuItem id={selectedCase.id} />
                  </Menu>
                </Box>
                <Modal open={editingCaseForModal}>
                  <Fade in={editingCaseForModal}>
                    <div className={classes.modal}>
                      <Box
                        onClick={e => {
                          e.stopPropagation()
                        }}
                      >
                        <ExistingCaseEditor
                          patient={patient}
                          handleClose={() => setEditingCaseForModal(!editingCaseForModal)}
                          caseId={selectedCase.id}
                          onAfterSave={handleOnAfterChangeCase}
                        />
                      </Box>
                    </div>
                  </Fade>
                </Modal>
                <Dialog
                  open={viewingCaseHistory}
                  onClose={(e: React.MouseEvent) => {
                    e.stopPropagation()
                    setViewingCaseHistory(false)
                  }}
                >
                  <Paper
                    style={{ maxHeight: '90vh', overflow: 'auto' }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    <CaseHistory id={selectedCase.id} />
                  </Paper>
                </Dialog>
                {showEmailModel && (
                  <EmailListView
                    selectedCaseId={selectedCase.id}
                    onClose={() => setShowEmailModel(false)}
                  />
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              mt={2}
              mb={2}
              ml={2}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box display={'inline-flex'} alignItems={'center'} flexWrap={'wrap'}>
                <Box
                  display="flex"
                  mr={1}
                  className={classes.ownerDropdown}
                  sx={{
                    '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                      maxWidth: '105px',
                    },
                  }}
                >
                  <UserGroupSelect
                    selectedValue={selectedCaseOwnerGroupOption}
                    options={flatOptions}
                    onChange={async ownerGroupOption => {
                      const ownerGroup = ownerGroupOption.value
                      if (ownerGroup)
                        await handleUpdateCase({
                          id: selectedCase.id,
                          ownerGroup,
                        })
                    }}
                    detailed
                    disabled={isClosed || isUpdatingCase}
                  />
                </Box>
                {isCaseMachineGenerated ? (
                  <Typography>Machine-Generated</Typography>
                ) : !selectedCase?.autoComplete ? (
                  <Autocomplete
                    disableClearable={true}
                    style={{ width: 180 }}
                    autoHighlight
                    value={{
                      dest: selectedCase.status || '',
                    }}
                    options={availableActions || []}
                    getOptionLabel={option =>
                      option.trigger && option.trigger !== option.dest
                        ? `${option.trigger} (${option.dest})`
                        : option.dest
                    }
                    classes={{ input: classes.autoCompleteText, option: classes.autoCompleteText }}
                    onChange={(event, newValue) => {
                      // If we are on the inactive tab, we are probably
                      // reopening the case so we can continue to make changes
                      // to it. Navigate to the case so we don't lose it if it
                      // moves to another tab.
                      if (workunitCompleteStates.includes(selectedCase.statusCategory)) {
                        navigateToCase(selectedCase.id)
                      }

                      event.stopPropagation()
                      onCaseActionChange(newValue?.trigger)
                    }}
                    renderInput={params => (
                      <TextField
                        onClick={e => {
                          e.stopPropagation()
                        }}
                        {...params}
                        size="small"
                        sx={{
                          '& fieldset': { border: 'none' },
                          '&': { backgroundColor: '#f5f5f5', borderRadius: '10px' },
                        }}
                      />
                    )}
                    disabled={isUpdatingCaseStatus}
                  />
                ) : (
                  <Box width={'180px'}></Box>
                )}
                <Box>
                  <HeaderNextActionDate
                    todo={todoContent}
                    editable={!isClosed}
                    onChange={async dueDate => {
                      await handleUpdateCase({
                        id: selectedCase.id,
                        dueDate,
                      })
                    }}
                  />
                </Box>
              </Box>
              <Box className={classes.msgTaskHolder}>
                <Box
                  style={{ minWidth: '46px' }}
                  className={linkedContentCount > 0 ? classes.mainIcon : classes.mainIconGrey}
                >
                  {!isCaseMachineGenerated && (
                    <HeaderExpandIcon
                      expandCase={selectedCaseId === parentId ? expandCase : false}
                      todoContent={todoContent}
                      expandHeader={() => setExpandHeader(!expandHeader)}
                    />
                  )}

                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      const existingQS = qs.parse(location.search)
                      existingQS.caseId = `${parentId}`
                      navigateToCase(parentId)
                    }}
                    color="inherit"
                    size="large"
                    sx={theme => ({
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: linkedContentCount >= 1 ? theme.palette.primary[700] : '',
                      },
                      borderRadius: 'unset',
                    })}
                  >
                    <ChatBubble className={classes.icon} />
                    <Typography className={classes.textContent}>
                      {`${linkedContentCount} ${
                        linkedContentCount > 1 || linkedContentCount == 0 ? 'msgs' : 'msg'
                      }`}
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box mt={1} className={classes.caseDescriptionHolder}>
              <Box className={classes.contentHolder}>
                <Typography pt={1} className={classes.parentCategory}>
                  {parentCategory}
                  {carePlanTitle ? ` / ` + carePlanTitle : ''}
                </Typography>
                {selectedCase.caseTags?.map(caseTag => {
                  return <CaseTag tag={caseTag.tag} key={String(caseTag.id)} />
                })}
              </Box>
              <Box className={classes.updatedHolder}>
                <Box className={classes.updatedContent}>
                  <Typography fontSize={'1.2rem'}>
                    {selectedCase.updatedAt
                      ? `Updated ${getUpdatedAtLabel(Moment(selectedCase.updatedAt).toDate())}`
                      : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </AccordionHeader>
      <AccordionDetails>
        {children}
        {caseCategory?.usesExternalDescription ? (
          <CaseMemberFacingInfo
            selectedCase={selectedCase}
            handleSubmit={async values => {
              await handleUpdateCase({
                id: selectedCase.id,
                ...values,
              })
            }}
            loading={isLoadingUpdateCase}
          />
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

// ExistingCaseEditor is a helper for lazily loading an existing case by ID, and then making it editable in the
// standard CaseEditor.
const ExistingCaseEditor = (props: Omit<CaseEditorProps, 'case'> & { caseId: ICase['id'] }) => {
  const { isLoading, data: patientCase } = useCase(props.caseId)

  if (isLoading) {
    return <Loader />
  }

  return <CaseEditor {...props} case={patientCase} />
}

const ReactJson = lazy(() => import('react-json-view'))

const CaseHistory = (props: { id: ICase['id'] }) => {
  const { isLoading, data } = useCaseEvents(props.id)

  if (isLoading) {
    return <Loader />
  }

  return (
    <List>
      {data?.results.map(result => {
        return (
          <ListItem key={result.pghId}>
            <ListItemText>
              <Typography>
                {getDueDateLabel(Moment(result.pghCreatedAt).toDate())}
                {result.pghContext.metadata.user
                  ? ` ${result.pghContext.metadata.user.firstName ?? 'Unknown'} ${
                      result.pghContext.metadata.user.lastName ?? 'User'
                    }`
                  : null}
              </Typography>
              <Suspense fallback={null}>
                <Box mt={2}>
                  <ReactJson
                    name="event"
                    displayDataTypes={false}
                    src={{
                      table: result.pghTable,
                      data: result.pghData,
                      label: result.pghLabel,
                      diff: result.pghDiff,
                    }}
                    onEdit={false}
                    onAdd={false}
                    onDelete={false}
                  />
                </Box>
              </Suspense>
            </ListItemText>
          </ListItem>
        )
      })}
    </List>
  )
}

const CaseMessageTranscriptMenuItem = (props: { id: ICase['id'] }) => {
  const [isCopying, setIsCopying] = useState(false)
  const { isLoading, data } = useCaseMessageTranscript(props.id)

  // Copy summary to clipboard when requested and ready.
  useEffect(() => {
    if (!isCopying) {
      return
    }

    if (isLoading || !data) {
      return
    }

    navigator.clipboard.writeText(data.text)
  }, [isCopying, isLoading, data])

  return (
    <MenuItem
      onClick={e => {
        e.stopPropagation()
        setIsCopying(true)
      }}
    >
      {isCopying ? isLoading ? <Loader /> : 'Copied' : 'Copy Message Transcript'}
    </MenuItem>
  )
}

const CarePlanHeader: FC<{
  parentId: number
  todoContent: Extract<PatientTodo, { contentType: 'careplan' }>
  patient: PatientUser
  parentCategory: string
}> = props => {
  const { parentId, todoContent, patient, children } = props
  const { classes } = useStyles()
  const client = useQueryClient()
  const dispatch = useDispatch()
  const me = useSelector(state => state.me)

  const [editingCareplan, setEditingCareplan] = useState<boolean>(false)

  const [scrollEl, setScrollEl] = useState<HTMLDivElement | null>(null)
  const [expandHeader, setExpandHeader] = useState<boolean>(false)
  const { selectedCarePlanId } = useContext(SelectedTodoContext)

  useEffect(() => {
    if (!selectedCarePlanId || selectedCarePlanId !== parentId) {
      return
    }

    scrollEl?.scrollIntoView()
  }, [scrollEl, selectedCarePlanId, parentId])

  const selectedCarePlan = todoContent.contentObject
  if (!selectedCarePlan) {
    return <></>
  }

  const headerClass = classNames({
    [classes.highlightContent]: selectedCarePlanId && selectedCarePlanId === parentId,
  })

  const closeModal = () => {
    // TODO: add useUpdateCareplan add use from careplan editor
    client.invalidateQueries([listCarePlansKey, patient.id])
    client.cancelQueries([listPersonTodoKey, patient.person.id])
    client.invalidateQueries([listPersonTodoKey, patient.person.id])
    setEditingCareplan(!editingCareplan)
  }

  return (
    <div>
      <Accordion defaultExpanded={!!selectedCarePlanId && selectedCarePlanId === parentId}>
        <AccordionHeader>
          <div ref={setScrollEl}>
            <Box
              paddingLeft={2.5}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              className={headerClass}
            >
              <Box display="flex" flexGrow={1}>
                <Typography className={classes.contentTitle}>
                  {selectedCarePlan ? selectedCarePlan.title : ''}
                </Typography>
              </Box>
              {editingCareplan && (
                <Box
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <CarePlansModal
                    closeModal={closeModal}
                    carePlan={selectedCarePlan}
                    open={editingCareplan}
                    patient={patient}
                    me={me}
                    handleModalAction={updatedCarePlan => {
                      dispatch(getTasksSuccess(updatedCarePlan.tasks))
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              mt={2}
              mb={2}
              ml={2}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box display={'inline-flex'} alignItems={'center'} flexWrap={'wrap'}>
                <Box width={'180px'} mr={1}></Box>
                <Box width={'180px'}></Box>
                <Box>
                  <HeaderNextActionDate todo={todoContent} />
                </Box>
              </Box>
              <Box className={classes.msgTaskHolder}>
                <Box style={{ minWidth: '46px' }} className={classes.mainIcon}>
                  <HeaderExpandIcon
                    todoContent={todoContent}
                    expandHeader={() => setExpandHeader(!expandHeader)}
                  />
                  <IconButton
                    className={classes.iconButton}
                    size="large"
                    sx={theme => ({
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      borderRadius: 'unset',
                    })}
                  >
                    <ChatBubble className={classes.icon} />
                    <Typography className={classes.textContent}>{'msgs not available'}</Typography>
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box mt={1} className={classes.caseDescriptionHolder}>
              <Box className={classes.contentHolder}>
                <Typography pt={0.5} className={classes.parentCategory}>
                  {'Care Plan'}
                </Typography>
              </Box>
              <Box className={classes.updatedHolder}>
                <Box className={classes.updatedContent}>
                  <Typography fontSize={'1.2rem'}>
                    {selectedCarePlan.updatedAt
                      ? `Updated ${getUpdatedAtLabel(Moment(selectedCarePlan.updatedAt).toDate())}`
                      : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
        </AccordionHeader>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  )
}

const ChatMessageHeader: FC<{
  todoContent: Extract<PatientTodo, { contentType: 'chatmessagev2' }>
}> = props => {
  const { todoContent, children } = props
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [expandHeader, setExpandHeader] = useState<boolean>(false)
  return (
    <Accordion expanded={expandHeader}>
      <AccordionHeader>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <Box paddingLeft={3} display="flex" alignItems="center" flexWrap="wrap">
            <MessageIcon className={classes.icon} />
            <Box paddingLeft={1}>
              <Typography className={classes.contentTitle}>messages</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <HeaderNextActionDate todo={todoContent} />
            <Box style={{ minWidth: '46px' }}></Box>
            <HeaderExpandIcon
              todoContent={todoContent}
              expandHeader={() => setExpandHeader(!expandHeader)}
            />
            <div>
              <IconButton
                className={classes.iconButton}
                onClick={e => {
                  setAnchorEl(e.currentTarget)
                }}
                size="large"
                sx={theme => ({
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  borderRadius: 'unset',
                })}
              >
                <MoreVertIcon className={classes.icon} />
              </IconButton>
              <Menu
                MenuListProps={{
                  disablePadding: true,
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={e => {
                  setAnchorEl(null)
                }}
              ></Menu>
            </div>
          </Box>
        </Box>
      </AccordionHeader>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

const HeaderExpandIcon: FC<{
  numberOfChildren?: number
  todoContent?: PatientTodo
  expandHeader: () => void
  expandCase?: boolean
}> = props => {
  const { numberOfChildren, todoContent, expandHeader, expandCase } = props
  const { classes } = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  // For expanding case when navigating from navigation tab
  useEffect(() => {
    if (expandCase) {
      setIsOpen(expandCase)
    }
  }, [expandCase])

  // If neither todoContent or numberOfChildren are passed, we just add an empty character to the expand icon
  let subtitle = 0
  // If numberOfChildren is passed in, this will take priority
  if (numberOfChildren) {
    subtitle = numberOfChildren
  }
  // Else, we look at the todoContent to determine nuber of children
  else if (todoContent) {
    // We only want to show the number of incomplete tasks, unless all tasks are complete
    const areTasksHidden: boolean =
      todoContent.items.some(item => item.contentObject.isComplete) &&
      todoContent.items.some(item => !item.contentObject.isComplete)
    const lengthOfTasks = todoContent.items.filter(todoItem => {
      if (!areTasksHidden) {
        return true
      }

      return !todoItem.contentObject.isComplete
    }).length
    subtitle = lengthOfTasks
  }

  return (
    <IconButton
      className={classes.iconButton}
      onClick={() => {
        expandHeader()
        setIsOpen(!isOpen)
      }}
      size="large"
      color={subtitle > 0 ? 'primary' : 'secondary'}
      sx={theme => ({
        '&:hover': {
          backgroundColor: 'transparent',
          color: subtitle > 0 ? theme.palette.primary[700] : '',
        },
        borderRadius: 'unset',
      })}
    >
      <ListIcon
        className={classes.icon}
        style={{
          width: iconSize,
          height: iconSize,
        }}
      />
      <Typography className={classes.textContent} style={{ marginLeft: 1 }}>
        {`${subtitle} ${subtitle > 1 || subtitle == 0 ? 'tasks' : 'task'}`}
      </Typography>
    </IconButton>
  )
}
